.hint-text {
	@apply font-medium text-xs mt-2;

	&.is {
		&--info {
			@apply text-blue-500;
		}
		&--valid {
			@apply text-green-500;
		}
		&--warning {
			@apply text-yellow-500;
		}
		&--error {
			@apply text-red-500;
		}
	}
}
